import './css/timeout.css'

import { loader } from '@/loader'

const timeoutPage = () => {
// Создаем основной контейнер
  const container = document.createElement('div')
  container.style.display = 'none'

  const header = document.createElement('h1')
  header.className = 'timeout__header'
  header.textContent = '503'
  header.style.margin = '0'

  const wrapper = document.createElement('div')
  wrapper.className = 'timeout__wrapper'

  const firstLine = document.createElement('p')
  firstLine.className = 'timeout__line'
  firstLine.innerHTML = '> <span id="timeout_message" class="timeout__message-title" style="font-weight: bold;"></span>'

  const secondLine = document.createElement('p')
  secondLine.className = 'timeout__line'
  secondLine.innerHTML = '> <a href="" id="reload_message" class="timeout__message-title" style="font-weight: bold;"></a>'

  wrapper.appendChild(firstLine)
  wrapper.appendChild(secondLine)

  container.appendChild(header)
  container.appendChild(wrapper)

  document.body.appendChild(container)

  const message = document.getElementById('timeout_message')
  const reloadMessage = document.getElementById('reload_message')

  return {
    show: function(text?: string, reloadText?: string) {
      if (message) {
        message.innerHTML = text ?? 'Сервис временно не доступен. Попробуйте позже.'
      }
      if (reloadMessage) {
        reloadMessage.innerHTML = reloadText ?? 'Попробовать ещё раз'
      }
      setTimeout(() => {
        loader.hide()
        container.style.display = 'block'
      }, 1000)
    },
    hide: function() {
      container.style.display = 'none'
    }
  }
}

export const useTimeout = () => {
  const timeout = timeoutPage()

  return {
    show: timeout.show,
    hide: timeout.hide
  }
}
