interface ApiEndpoint {
  method: string;
  path: string;
}

interface Permission {
  permission: string;
  code: number;
  backendModule: string;
  backendFeature: string | null;
  api: ApiEndpoint[];
}

// Константы для часто используемых значений
const BACKEND_MODULE = {
  EMS_MAIN: 'zep-ems-main'
} as const

const BACKEND_FEATURE = {
  MAIN: 'main',
  EMS_MAIN: 'ems-main',
  CONTACTS: 'contacts',
  JOURNAL: 'journal'
} as const

const API_PATHS = {
  AUTHORITY_GROUPS: '/v1/authority-groups/',
  AUTHORITY_GROUP_BY_ID: '/v1/authority-groups/{id}',
  USERS: '/v1/users/',
  DEVICE_ROLES: '/v1/device-roles/',
  DEVICE_ROLE_BY_ID: '/v1/device-roles/{id}',
  REGIONS: '/v1/regions/',
  REGION_BY_ID: '/v1/regions/{id}',
  SITE_GROUPS: '/v1/site-groups/',
  SITE_GROUP_BY_ID: '/v1/site-groups/{id}',
  SITES: '/v1/sites/',
  SITE_BY_ID: '/v1/sites/{id}',
  LOCATIONS: '/v1/locations/',
  LOCATION_BY_ID: '/v1/locations/{id}',
  TAGS: '/v1/tags/',
  TAG_BY_ID: '/v1/tags/{id}',
  MANUFACTURERS: '/v1/manufacturers/',
  MANUFACTURER_BY_ID: '/v1/manufacturers/{id}',
  DEVICES: '/v1/devices/',
  DEVICE_BY_ID: '/v1/devices/{id}',
  DEVICE_TYPES: '/v1/device-types/',
  DEVICE_TYPE_BY_ID: '/v1/device-types/{id}',
  DEVICE_MODELS: '/v1/device-models/',
  DEVICE_MODEL_BY_ID: '/v1/device-models/{id}',
  JOURNAL_CHANGES: '/v1/journal-changes/',
  JOURNAL_CHANGE_BY_ID: '/v1/journal-changes/{id}',
  ALARMS: '/v1/alarms/',
  ALARM_BY_ID: '/v1/alarms/{id}',
  ALARM_LEVELS: '/v1/alarms-levels/',
  ALARM_LEVEL_BY_ID: '/v1/alarms-levels/{id}',
  ALARM_CATEGORIES: '/v1/alarms-categories/',
  ALARM_CATEGORY_BY_ID: '/v1/alarms-categories/{id}',
  ALARM_TYPES: '/v1/alarms-types/',
  ALARM_TYPE_BY_ID: '/v1/alarms-types/{id}',
  ALARM_ACK: '/v1/alarms/{id}/ack',
  ALARM_UNACK: '/v1/alarms/{id}/unack',
  REPORTS: '/v1/reports/*',
  RACKS: '/v1/racks/',
  RACK_BY_ID: '/v1/racks/{id}',
  CONTACTS: '/v1/contacts/',
  CONTACT_BY_ID: '/v1/contacts/{id}',
  COUNTERS: '/v1/counters/',
  COUNTER_BY_ID: '/v1/counters/{id}',
  PORT_TYPES: '/v1/port-types/*',
  DRIVERS: '/v1/drivers',
  DRIVER_BY_ID: '/v1/drivers/{id}',
  DRIVER_INSTANCES: '/v1/driver-instances/',
  DRIVER_INSTANCE_BY_ID: '/v1/driver-instances/{id}',
  DRIVER_ALARM_LEVELS: '/v1/driver-alarm-levels/*',
  DRIVER_ALARM_CATEGORIES: '/v1/driver-alarm-categories/*'
} as const

const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete'
} as const

const permissions: Permission[] = [
  {
    permission: 'authority-groups.read',
    code: 1,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.AUTHORITY_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.AUTHORITY_GROUP_BY_ID }
    ]
  },
  {
    permission: 'authority-groups.manage',
    code: 2,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.AUTHORITY_GROUPS },
      { method: HTTP_METHOD.POST, path: API_PATHS.AUTHORITY_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.AUTHORITY_GROUP_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.AUTHORITY_GROUP_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.AUTHORITY_GROUP_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.USERS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_ROLES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_ROLE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGION_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUP_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATION_BY_ID }
    ]
  },
  {
    permission: 'changelog.read',
    code: 3,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGES },
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGE_BY_ID }
    ]
  },
  {
    permission: 'users.read',
    code: 4,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.USERS }
    ]
  },
  {
    permission: 'device-roles.manage',
    code: 6,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_ROLES },
      { method: HTTP_METHOD.POST, path: API_PATHS.DEVICE_ROLES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_ROLE_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.DEVICE_ROLE_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.DEVICE_ROLE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS }
    ]
  },
  {
    permission: 'regions.manage',
    code: 7,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.POST, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGION_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.REGION_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.REGION_BY_ID }
    ]
  },
  {
    permission: 'site-groups.manage',
    code: 8,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.POST, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUP_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.SITE_GROUP_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.SITE_GROUP_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS }
    ]
  },
  {
    permission: 'sites.manage',
    code: 9,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES },
      { method: HTTP_METHOD.POST, path: API_PATHS.SITES },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.SITE_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.SITE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS }
    ]
  },
  {
    permission: 'locations.manage',
    code: 10,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.POST, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATION_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.LOCATION_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.LOCATION_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES }
    ]
  },
  {
    permission: 'manufacturers.manage',
    code: 12,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.MANUFACTURERS },
      { method: HTTP_METHOD.POST, path: API_PATHS.MANUFACTURERS },
      { method: HTTP_METHOD.GET, path: API_PATHS.MANUFACTURER_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.MANUFACTURER_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.MANUFACTURER_BY_ID }
    ]
  },
  {
    permission: 'device-types.manage',
    code: 13,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.CONTACTS,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.POST, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPE_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.DEVICE_TYPE_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.DEVICE_TYPE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS }
    ]
  },
  {
    permission: 'device-models.manage',
    code: 14,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.CONTACTS,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS },
      { method: HTTP_METHOD.POST, path: API_PATHS.DEVICE_MODELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODEL_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.DEVICE_MODEL_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.DEVICE_MODEL_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.PORT_TYPES },
      { method: HTTP_METHOD.POST, path: API_PATHS.PORT_TYPES },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.PORT_TYPES },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.PORT_TYPES }
    ]
  },
  {
    permission: 'contacts.read',
    code: 15,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.JOURNAL,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.CONTACTS },
      { method: HTTP_METHOD.GET, path: API_PATHS.CONTACT_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS }
    ]
  },
  {
    permission: 'contacts.manage',
    code: 16,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.JOURNAL,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.CONTACTS },
      { method: HTTP_METHOD.POST, path: API_PATHS.CONTACTS },
      { method: HTTP_METHOD.GET, path: API_PATHS.CONTACT_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.CONTACT_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.CONTACT_BY_ID }
    ]
  },
  {
    permission: 'journal.read',
    code: 17,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGES },
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGE_BY_ID }
    ]
  },
  {
    permission: 'journal.manage',
    code: 18,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGES },
      { method: HTTP_METHOD.POST, path: API_PATHS.JOURNAL_CHANGES },
      { method: HTTP_METHOD.GET, path: API_PATHS.JOURNAL_CHANGE_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.JOURNAL_CHANGE_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.JOURNAL_CHANGE_BY_ID }
    ]
  },
  {
    permission: 'tags.read',
    code: 19,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAG_BY_ID }
    ]
  },
  {
    permission: 'tags.manage',
    code: 20,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.POST, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAG_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.TAG_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.TAG_BY_ID }
    ]
  },
  {
    permission: 'alarms.active.read',
    code: 21,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARMS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES }
    ]
  },
  {
    permission: 'alarms.active.manage',
    code: 22,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.POST, path: API_PATHS.ALARM_ACK },
      { method: HTTP_METHOD.POST, path: API_PATHS.ALARM_UNACK },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARMS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES }
    ]
  },
  {
    permission: 'alarms.history.read',
    code: 23,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARMS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES }
    ]
  },
  {
    permission: 'alarms.levels.manage',
    code: 24,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.POST, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_LEVEL_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.ALARM_LEVELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS }
    ]
  },
  {
    permission: 'alarms.categories.manage',
    code: 25,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARMS },
      { method: HTTP_METHOD.POST, path: API_PATHS.ALARMS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_CATEGORIES },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.ALARM_CATEGORIES },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.ALARM_CATEGORIES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS }
    ]
  },
  {
    permission: 'alarms.types.manage',
    code: 26,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.POST, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPE_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.ALARM_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS }
    ]
  },
  {
    permission: 'reports.active-alarms.generate',
    code: 27,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.POST, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES }
    ]
  },
  {
    permission: 'reports.history-alarms.generate',
    code: 28,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.POST, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.REPORTS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.REGIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.ALARM_TYPES }
    ]
  },
  {
    permission: 'reports.my-task-generated.read',
    code: 29,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: []
  },
  {
    permission: 'reports.user-generated.read',
    code: 30,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.REPORTS }
    ]
  },
  {
    permission: 'reports.task-generated.read',
    code: 31,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.tasks.read',
    code: 32,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.tasks.manage',
    code: 33,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.reason-alarms.generate',
    code: 42,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.source-alarms.generate',
    code: 43,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.reason-alarms-avg.generate',
    code: 44,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.source-alarms-avg.generate',
    code: 46,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.reason-source-alarms-avg.generate',
    code: 45,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.reason-alarms-interval.generate',
    code: 47,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'reports.source-alarms-interval.generate',
    code: 48,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.MAIN,
    api: []
  },
  {
    permission: 'counters.read',
    code: 34,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: []
  },
  {
    permission: 'counters.manage',
    code: 35,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.COUNTERS },
      { method: HTTP_METHOD.GET, path: API_PATHS.COUNTER_BY_ID }
    ]
  },
  {
    permission: 'racks.read',
    code: 36,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: BACKEND_FEATURE.EMS_MAIN,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.RACKS },
      { method: HTTP_METHOD.GET, path: API_PATHS.RACK_BY_ID }
    ]
  },
  {
    permission: 'racks.manage',
    code: 37,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: null,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.RACKS },
      { method: HTTP_METHOD.POST, path: API_PATHS.RACKS },
      { method: HTTP_METHOD.GET, path: API_PATHS.RACK_BY_ID },
      { method: HTTP_METHOD.PATCH, path: API_PATHS.RACK_BY_ID },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.RACK_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS }
    ]
  },
  {
    permission: 'devices.read',
    code: 38,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: null,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS }
    ]
  },
  {
    permission: 'devices.manage',
    code: 39,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: null,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITES },
      { method: HTTP_METHOD.GET, path: API_PATHS.LOCATIONS },
      { method: HTTP_METHOD.GET, path: API_PATHS.SITE_GROUPS },
      { method: HTTP_METHOD.GET, path: API_PATHS.RACKS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_ROLES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_MODELS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DEVICE_TYPES },
      { method: HTTP_METHOD.GET, path: API_PATHS.TAGS },
      { method: HTTP_METHOD.GET, path: API_PATHS.MANUFACTURERS },
      { method: HTTP_METHOD.DELETE, path: API_PATHS.MANUFACTURERS }
    ]
  },
  {
    permission: 'drivers-state.read',
    code: 40,
    backendModule: BACKEND_MODULE.EMS_MAIN,
    backendFeature: null,
    api: [
      { method: HTTP_METHOD.GET, path: API_PATHS.DRIVERS },
      { method: HTTP_METHOD.GET, path: API_PATHS.DRIVER_BY_ID },
      { method: HTTP_METHOD.GET, path: API_PATHS.DRIVER_INSTANCES },
      { method: HTTP_METHOD.GET, path: API_PATHS.DRIVER_INSTANCE_BY_ID }
    ]
  }
] as const

// Создаем типы на основе существующих данных
type PermissionCode = typeof permissions[number]['code'];
type PermissionName = typeof permissions[number]['permission'];
type BackendModule = typeof permissions[number]['backendModule'];
type BackendFeature = typeof permissions[number]['backendFeature'];

// Экспортируем типы и данные
export type {
  ApiEndpoint,
  Permission,
  PermissionCode,
  PermissionName,
  BackendModule,
  BackendFeature
}

export {
  BACKEND_MODULE,
  BACKEND_FEATURE,
  API_PATHS,
  HTTP_METHOD
}

export {
  permissions as permissionsList
}