import 'reflect-metadata'

import { createApp } from 'vue'
import VueKonva from 'vue-konva'
import draggable from 'vuedraggable'
// import VueApexCharts from "vue3-apexcharts"

import {
  useFramework,
  useEndpoint,
  useI18nService,

  type IFrameworkOptions
} from '@zephyr/framework'

import { styleFramework } from '@zephyr/style-framework'
import { options as themeOptions } from '@zephyr/portal-theme'

import { initRouter } from '@/router'

import App from './App.vue'
import { loader } from './loader'
import { useTimeout } from '@/composable/use-timeout/useTimeout'
// import { timeout } from './timeout'

import { permissionsList } from './permissions/permissions-codes'

// const { msw } = await import('../public/mocks/browser.js')
// await msw.start()

const timeout = useTimeout()

const frameworkOptions: IFrameworkOptions = {
  ...themeOptions,
  config: {
    ...themeOptions.config,
    prefix: 'zep'
  }
}
// loader.info('Получение конфигурации')

const {
  onReady,
  getAppConfig,
  getPermissions
} = useEndpoint('tenant-app', { configUrl: 'api/meta/v1/endpoint' })

getPermissions().setPermissionsDictionary(permissionsList)

onReady(
  async () => {
    console.info('Запуск приложения')
    const { portal, uiModules } = getAppConfig()

    // TODO: перенести во фреймворк после отладки и настройки работы всего сотального
    const _uiModules: { module: string; url: string }[] = []
    uiModules?.forEach((module: string) => {
      _uiModules.push({
        module,
        url: `/ui/modules/${module}/assets/remote-entry.js?${Date.now()}`
      })
      // TODO: вынести во фреймворк
      const url = `/ui/modules/${module}/styles/index.css?${Date.now()}`
      if (!document.querySelector(`link[href="${url}"]`)) {
        const link = document.createElement('link')
        link.href = url
        link.setAttribute('async', '')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        document.head.appendChild(link)
      }
    })
    window.ZepRemoteModules = _uiModules

    const ServiceI18n = useI18nService()
    await ServiceI18n.setLocale()
    await ServiceI18n.loadTranslationPack('overlay')

    const router = await initRouter(portal?.routes || [])
    loader.hide()

    createApp(App)
      .use(useFramework(), frameworkOptions)
      .use(router)
      .use(ServiceI18n.i18n)
      .use(VueKonva)
      .use(styleFramework)
      .component('draggable', draggable)
      .mount('#app')
  },
  () => {
    console.log('Init Config get Error')
    timeout.show()
  }
)